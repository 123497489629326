import { Card, Group, Text } from '@mantine/core'
import { currencyFormatter } from '@ospace/common'

import { CopyWidgetButton } from './copy-widget-button'

export const PipelineStatus = ({
  currency,
  mql,
  sql,
  qualified,
  total,
}: {
  currency: string
  mql: number
  sql: number
  qualified: number
  total: number
}) => {
  const { format } = currencyFormatter(true)
  const { targetRef, copyButton } = CopyWidgetButton({ title: 'Pipeline Status' })
  return (
    <Card shadow='sm' radius='md' h='100%' withBorder c='#868e96' ref={targetRef}>
      <Group position='apart'>
        <Text fw={600}>PIPELINE STATUS:</Text>
        {copyButton}
      </Group>
      <Text fz='sm'>MONTH 4 OF 12 MONTHS PROGRAM</Text>
      <div
        style={{
          padding: 10,
        }}
      >
        <div>
          <Text>MQL Stage</Text>
          <Text fw={600} c={'#000'}>
            {currency} {format(mql)}
          </Text>
        </div>
        <div
          style={{
            paddingTop: 10,
          }}
        >
          <Text>SQL Stage</Text>
          <Text fw={600} c={'#000'}>
            {currency} {format(sql)}
          </Text>
        </div>
        <div
          style={{
            paddingTop: 10,
          }}
        >
          <Text>Qualified</Text>
          <Text fw={600} c={'#000'}>
            {currency} {format(qualified)}
          </Text>
        </div>
      </div>
      <div
        style={{
          padding: 10,
          backgroundColor: '#ebf5fe',
        }}
      >
        <Text>Total Current Pipeline</Text>
        <Group spacing='xs' align='baseline'>
          <Text fw={600} c={'#000'}>
            {currency}
          </Text>
          <Text fw={600} fz={30} c={'#000'}>
            {format(total)}
          </Text>
        </Group>
      </div>
    </Card>
  )
}
