import { useParams } from 'react-router-dom'
import { Card, Flex, Grid } from '@mantine/core'
import { ProgramLayout } from '@ospace/layout/components/ProgramLayout'
import { ErrorBoundary } from '@ospace/shared'

import {
  ContactsDiscoveryCompaniesCard,
  ContactsDiscoveryContactsCard,
} from '../../components/ContactsDiscoveryCard'
import { MultiChannelWidget, TeleSalesWidget } from '../../components/outreach-widget'
import OpportunitiesTable from '../../components/partials/dashboard/OpportunitiesTable'
import { PipelineStatus } from '../../components/PipelineStatus'
import { useDeals } from '../../hooks'

type Props = { campaignId: number }

export const NewOverviewPage = (props: Props) => {
  const deals = useDeals(props.campaignId)

  return (
    <ProgramLayout status={deals.isFetching ? 'loading' : 'ready'} pageId='programs' fluid={true}>
      <>
        <Flex gap='sm' align='stretch' pt='sm'>
          <Grid
            style={{
              flexBasis: '50%',
            }}
          >
            <Grid.Col span={6}>
              <Card shadow='sm' radius='md' withBorder c='#868e96'>
                REVENUE FORECAST
              </Card>
            </Grid.Col>
            <Grid.Col span={6} h='100%'>
              <PipelineStatus
                qualified={112121}
                currency={'AUD'}
                sql={213232}
                mql={123123}
                total={34334}
              />
            </Grid.Col>
          </Grid>
          <Flex
            align='stretch'
            style={{
              flexBasis: '50%',
            }}
            direction='column'
          >
            <Flex gap='sm' align='stretch'>
              <div
                style={{
                  flexBasis: '80%',
                  paddingTop: 'sm',
                }}
              >
                <MultiChannelWidget emailsSent={250} openRate='26%' requests={165} accepted={37} />
              </div>
              <div
                style={{
                  flexBasis: '20%',
                }}
              >
                <ContactsDiscoveryCompaniesCard companies={20} />
              </div>
            </Flex>
            <Flex gap='sm' align='stretch'>
              <div
                style={{
                  flexBasis: '80%',
                  paddingTop: '0.75rem',
                }}
              >
                <TeleSalesWidget
                  htn={{
                    value: 250,
                    delta: '+ 12 this week',
                  }}
                  ltn={{
                    value: 150,
                    delta: '+ 22 this week',
                  }}
                  meetingsBooked={{
                    value: 165,
                    delta: '+ 32 this week',
                  }}
                  requestedCallbacks={{
                    value: 137,
                    delta: '+ 1 this week',
                  }}
                />
              </div>
              <div
                style={{
                  flexBasis: '20%',
                }}
              >
                <ContactsDiscoveryContactsCard contacts={250} rateOfChange='+ 12' />
              </div>
            </Flex>
          </Flex>
        </Flex>
        <OpportunitiesTable deals={deals} />
      </>
    </ProgramLayout>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)

  return <NewOverviewPage campaignId={campaignId} />
}

export const NewOverviewPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
